@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'PoiretOne', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: 'PoiretOne' !important;
}

html {
  overflow: hidden;
}
body {
	font-family: 'PoiretOne', sans-serif;
	min-height: 100vh;
	color: #57585c;
	color: var(--color-text);
  overflow: hidden;
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.minted-gradient-text {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: text !important;
  -webkit-mask-image: linear-gradient(#52efff, #ff00b7) !important;
  display: inline-block !important;
  color: white!important;
}

/* width */
::-webkit-scrollbar {
  z-index: 1000;
  width: 5px;
  background: transparent;
  position: absolute
}

/* Track */
::-webkit-scrollbar-track {
  z-index: 1000;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff9ff2;
  z-index: 1000;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  z-index: 1000;

}

.social-icons {
  filter: invert(1);
}
